import { createContext, useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { getUniqueNodeId, debounceFn } from 'utils/genericHelper';
import { cloneDeep, isEqual } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { UCI } from 'socket-package';
import { baseURL } from 'store/constant';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from 'store/actions';
import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { IconX } from '@tabler/icons';

const initialValue = {
  reactFlowInstance: null,
  setReactFlowInstance: () => {},
  duplicateNode: () => {},
  deleteNode: () => {},
  deleteEdge: () => {},
};

export const flowContext = createContext(initialValue);

export const ReactFlowContext = ({ children }) => {
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [debugData, setDebugData] = useState([]);
  const [faultyId, setFaultyId] = useState('');
  const [otherId, setOtherId] = useState([]);
  const [debugErrorMsg, setDebugErrorMsg] = useState('');
  //undo-redo implementation
  const [currentVal, setCurrentVal] = useState({});
  const [history, setHistory] = useState([]);
  const [redoArray, setRedoArray] = useState([]);
  const [showInputBox, setShowInputBox] = useState(true);
  const messageSocketRef = useRef(null);
  const debugSocketRef = useRef(null);
  const [messageSocket, setMessageSocket] = useState();
  const [debugDataSocket, setDebugDataSocket] = useState();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [conversationId, setConversationId] = useState(sessionStorage.getItem('conversationId'));
  const [loading, setLoading] = useState(false);
  const [deviceId, setDeviceId] = useState(uuidv4());
  const [userId, setUserId] = useState(uuidv4());
  const URL = process.env.REACT_APP_SOCKET_URL;
  const BotId = sessionStorage.getItem('botId');
  const [messages, setMessages] = useState([]);
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  const saveData = () => {
    if (reactFlowInstance) {
      const flowData = reactFlowInstance.toObject();
      console.log('flowData called', flowData);
      // Remove viewport from flowData object
      const { viewport, ...flowDataWithoutViewport } = flowData;
      let toReturn = false;
      flowDataWithoutViewport.nodes.forEach((node) => {
        if (!node.height || !node.width) {
          toReturn = true;
        }
      });
      if (toReturn) return;
      // console.log('comparison', {flowDataWithoutViewport, historyElem: history[history.length - 1]});

      console.log('flowData', flowDataWithoutViewport);
      console.log('currentVal', currentVal);
      if (history.length > 0 && isEqual(flowDataWithoutViewport, history[history.length - 1])) {
        console.log('No data need to be changed');
      } else {
        //clear redoArray
        setRedoArray([]);
        if (!isEqual(currentVal, {})) {
          // console.log("currentVal",currentVal)
          //update history array with prev val of currentVal and update currentVal to latest val
          setHistory((history) => [...history, currentVal]);
        }
        setCurrentVal(flowDataWithoutViewport);
      }
    }
  };

  const deleteNode = (nodeid) => {
    deleteConnectedInput(nodeid, 'node');
    reactFlowInstance.setNodes(reactFlowInstance.getNodes().filter((n) => n.id !== nodeid));
    reactFlowInstance.setEdges(
      reactFlowInstance.getEdges().filter((ns) => ns.source !== nodeid && ns.target !== nodeid)
    );
  };

  const deleteEdge = (edgeid) => {
    console.log('the edge id is', edgeid);
    deleteConnectedInput(edgeid, 'edge');
    reactFlowInstance.setEdges(reactFlowInstance.getEdges().filter((edge) => edge.id !== edgeid));
  };

  const deleteConnectedInput = (id, type) => {
    const connectedEdges =
      type === 'node'
        ? reactFlowInstance.getEdges().filter((edge) => edge.source === id)
        : reactFlowInstance.getEdges().filter((edge) => edge.id === id);

    for (const edge of connectedEdges) {
      const targetNodeId = edge.target;
      const sourceNodeId = edge.source;
      const targetInput = edge.targetHandle.split('-')[2];

      reactFlowInstance.setNodes((nds) =>
        nds.map((node) => {
          if (node.id === targetNodeId) {
            let value;
            const inputAnchor = node.data.inputAnchors.find((ancr) => ancr.name === targetInput);
            const inputParam = node.data.inputParams.find((param) => param.name === targetInput);

            if (inputAnchor && inputAnchor.list) {
              const values = node.data.inputs[targetInput] || [];
              value = values.filter((item) => !item.includes(sourceNodeId));
            } else if (inputParam && inputParam.acceptVariable) {
              value =
                node.data.inputs[targetInput].replace(`${sourceNodeId}.data.instance`, '') || '';
            } else {
              value = '';
            }
            node.data = {
              ...node.data,
              inputs: {
                ...node.data.inputs,
                [targetInput]: value,
              },
            };
          }
          return node;
        })
      );
    }
  };
  const debouncedSaveData = debounceFn(saveData, 69);
  const duplicateNode = (id) => {
    const nodes = reactFlowInstance.getNodes();
    const originalNode = nodes.find((n) => n.id === id);
    if (originalNode) {
      const newNodeId = getUniqueNodeId(originalNode.data, nodes);
      const clonedNode = cloneDeep(originalNode);

      const duplicatedNode = {
        ...clonedNode,
        id: newNodeId,
        position: {
          x: clonedNode.position.x + 400,
          y: clonedNode.position.y,
        },
        positionAbsolute: {
          x: clonedNode.positionAbsolute.x + 400,
          y: clonedNode.positionAbsolute.y,
        },
        data: {
          ...clonedNode.data,
          id: newNodeId,
        },
        selected: false,
      };

      const dataKeys = ['inputParams', 'inputAnchors', 'outputAnchors'];

      for (const key of dataKeys) {
        for (const item of duplicatedNode.data[key]) {
          if (item.id) {
            item.id = item.id.replace(id, newNodeId);
          }
        }
      }
      reactFlowInstance.setNodes([...nodes, duplicatedNode]);
    }
    debouncedSaveData();
  };

  // console.log("the debug data is ",debugData)
  const [currentNodeIds, setCurrentNodeIds] = useState([]);
  const checkNode = useCallback(() => {
    let faultyNodeId = null;
    let errMessage = '';
    const currentNodes = [];

    for (const item of debugData) {
      const currentNodeId = item?.metaData?.debugInfoPacket?.state?.currentState?.nodeId;
      const previousNodeId = item?.metaData?.debugInfoPacket?.state?.previousState?.nodeId;

      if (currentNodeId === 'error') {
        faultyNodeId = previousNodeId;
        errMessage = item?.metaData?.debugInfoPacket?.state?.currentState?.error;
        break;
      }
    }
    for (const item of debugData) {
      const currentNodeId = item?.metaData?.debugInfoPacket?.state?.currentState?.nodeId;
      if (currentNodeId !== faultyNodeId) {
        currentNodes.push(currentNodeId);
      }
    }

    setFaultyId(faultyNodeId);
    setDebugErrorMsg(errMessage);
    setCurrentNodeIds(currentNodes);
  }, [debugData]);
  useEffect(() => {
    checkNode();
  }, [debugData]);

  const sendMessage = useCallback(
    async (text, media, isVisibile = true) => {
      if (!sessionStorage.getItem('conversationId')) {
        const cId = uuidv4();
        console.log('convId', cId);
        setConversationId(() => {
          sessionStorage.setItem('conversationId', cId);
          return cId;
        });
      } else sessionStorage.setItem('conversationId', conversationId || '');
      // console.log('my mssg:', text);
      const messageId = uuidv4();
      if (!BotId) {
        enqueueSnackbar({
          message: 'Bot Id Not present',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            action: (key) => (
              <Button style={{ color: 'white' }} onClick={() => closeSnackbar(key)}>
                <IconX />
              </Button>
            ),
          },
        });
        setLoading(false);
        return;
      }
      setLoading(true);
      messageSocket.sendMessage({
        payload: {
          app: BotId,
          payload: {
            text: text?.replace('&', '%26')?.replace(/^\s+|\s+$/g, ''),
            metaData: {
              debugId: deviceId,
            },
          },
          tags: JSON.parse(sessionStorage.getItem('tags') || '[]') || [],
          from: {
            userID: userId,
          },
          messageId: {
            Id: messageId,
            channelMessageId: sessionStorage.getItem('conversationId'),
          },
        },
      });
      setDebugData([]);
      if (isVisibile)
        if (media) {
          console.log('media is present');
          // if (media.mimeType.slice(0, 5) === 'image') {
          // } else if (media.mimeType.slice(0, 5) === 'audio' && isVisibile) {
          // } else if (media.mimeType.slice(0, 5) === 'video') {
          // } else if (media.mimeType.slice(0, 11) === 'application') {
          // } else {
          // }
        } else {
          setMessages((prev) => [
            ...prev.map((prevMsg) => ({ ...prevMsg, disabled: true })),
            {
              text: text?.replace(/^\s+|\s+$/g, '')?.replace(/^Guided:/, ''),
              position: 'right',
              payload: { text },
              time: Date.now(),
              type: 'userMessage',
              disabled: true,
              messageId: messageId,
              conversationId: sessionStorage.getItem('conversationId'),
              repliedTimestamp: Date.now(),
            },
          ]);
        }
    },
    [conversationId, messageSocket]
  );

  const updateMsgState = useCallback(
    async ({ msg, media }) => {
      if (
        msg?.payload?.text &&
        msg?.messageId?.Id &&
        msg?.messageId?.channelMessageId &&
        msg?.messageId?.replyId
      ) {
        if (sessionStorage.getItem('conversationId') === msg.messageId.channelMessageId) {
          const word = msg.payload.text;

          setMessages((prev) => {
            const updatedMessages = [...prev];
            const existingMsgIndex = updatedMessages.findIndex(
              (m) => m.messageId === msg.messageId.Id
            );
            // console.log('existingMsgIndex', existingMsgIndex);

            if (existingMsgIndex !== -1) {
              // Update the existing message with the new word
              if (word.endsWith('<end/>')) {
                updatedMessages[existingMsgIndex].isEnd = true;
              }
              updatedMessages[existingMsgIndex].text = word.replace(/<end\/>/g, '') + ' ';
            } else {
              // If the message doesn't exist, create a new one
              const newMsg = {
                text: word.replace(/<end\/>/g, '') + ' ',
                isEnd: word.endsWith('<end/>') ? true : false,
                choices: msg?.payload?.buttonChoices,
                position: 'left',
                reaction: 0,
                messageId: msg?.messageId.Id,
                replyId: msg?.messageId.replyId,
                conversationId: msg.messageId.channelMessageId,
                sentTimestamp: Date.now(),
                card: msg?.payload?.card,
                isGuided: msg?.transformer?.metaData?.isGuided || false,
                // btns: msg?.payload?.buttonChoices,
                // audio_url: msg?.content?.audio_url,
                // metaData: msg.payload?.metaData
                //     ? JSON.parse(msg.payload?.metaData)
                //     : null,
                ...media,
              };

              updatedMessages.push(newMsg);
            }
            return updatedMessages;
          });
          setLoading(false);
        }
      }
    },
    [messages]
  );

  useEffect(() => {
    messageSocketRef.current = messageSocket;
  }, [messageSocket]);

  useEffect(() => {
    debugSocketRef.current = debugDataSocket;
  }, [debugDataSocket]);

  const onMessageReceived = useCallback(
    async (msg) => {
      const ackMessage = JSON.parse(JSON.stringify(msg));
      ackMessage.messageType = 'ACKNOWLEDGEMENT';
      console.log(msg);
      messageSocketRef?.current?.sendMessage({
        payload: ackMessage,
      });
      // if (!msg?.content?.id) msg.content.id = '';
      if (msg.messageType.toUpperCase() === 'IMAGE') {
        if (
          // msg.content.timeTaken + 1000 < timer2 &&
          isOnline
        ) {
          await updateMsgState({
            msg: msg,
            media: { imageUrls: msg?.content?.media_url },
          });
        }
      } else if (msg.messageType.toUpperCase() === 'AUDIO') {
        updateMsgState({
          msg,
          media: { audioUrl: msg?.content?.media_url },
        });
      } else if (msg.messageType.toUpperCase() === 'HSM') {
        updateMsgState({
          msg,
          media: { audioUrl: msg?.content?.media_url },
        });
      } else if (msg.messageType.toUpperCase() === 'VIDEO') {
        updateMsgState({
          msg,
          media: { videoUrl: msg?.content?.media_url },
        });
      } else if (
        msg.messageType.toUpperCase() === 'DOCUMENT' ||
        msg.messageType.toUpperCase() === 'FILE'
      ) {
        updateMsgState({
          msg,
          media: { fileUrl: msg?.content?.media_url },
        });
      } else if (msg.messageType.toUpperCase() === 'TEXT') {
        if (isOnline) {
          await updateMsgState({
            msg: msg,
            media: null,
          });
        }
      }
    },
    [isOnline, updateMsgState]
  );
  const onDebugDataReceived = useCallback(
    async (msg) => {
      const ackMessage = JSON.parse(JSON.stringify(msg));
      ackMessage.messageType = 'ACKNOWLEDGEMENT';
      console.log(msg);
      debugSocketRef?.current?.sendMessage({
        payload: ackMessage,
      });
      if (msg.messageType.toUpperCase() === 'DEBUG') {
        setDebugData((prev) => [...prev, msg.payload]);
      }
    },
    [isOnline, updateMsgState]
  );
  useEffect(() => {
    if (userId) {
      setMessageSocket(
        new UCI(
          URL,
          {
            transportOptions: {
              polling: {
                extraHeaders: {
                  channel: 'akai',
                },
              },
            },
            path: process.env.REACT_PUBLIC_SOCKET_PATH || '',
            query: {
              deviceId: userId,
            },
            autoConnect: false,
            transports: ['polling', 'websocket'],
            upgrade: true,
          },
          onMessageReceived
        )
      );
    }
    function cleanup() {
      if (messageSocket)
        messageSocket.onDisconnect(() => {
          console.log('Socket disconnected');
        });
    }
    return cleanup;
  }, [BotId]);
  useEffect(() => {
    if (deviceId) {
      setDebugDataSocket(
        new UCI(
          URL,
          {
            transportOptions: {
              polling: {
                extraHeaders: {
                  channel: 'akai',
                },
              },
            },
            path: process.env.REACT_PUBLIC_SOCKET_PATH || '',
            query: {
              deviceId: deviceId,
            },
            autoConnect: false,
            transports: ['polling', 'websocket'],
            upgrade: true,
          },
          onDebugDataReceived
        )
      );
    }
    function cleanup() {
      if (debugDataSocket)
        debugDataSocket.onDisconnect(() => {
          console.log('Socket 2 disconnected');
        });
    }
    return cleanup;
  }, [BotId]);

  const changeId = (id, newId) => {
    const nodes = cloneDeep(reactFlowInstance.getNodes());
    const edges = cloneDeep(reactFlowInstance.getEdges());
    const originalNode = nodes.find((n) => n.id === id);
    const nodeIndex = nodes.findIndex((node) => node.id === id);

    if (!originalNode) {
      console.error(`Node with id ${id} not found.`);
      return;
    }

    const newEdges = edges.map((edge) => {
      let updatedEdge = { ...edge };

      if (edge.source === id) {
        updatedEdge.source = newId;
      }
      if (edge.target === id) {
        updatedEdge.target = newId;
      }
      if (updatedEdge.sourceHandle && updatedEdge.sourceHandle.includes(id)) {
        updatedEdge.sourceHandle = updatedEdge.sourceHandle.replace(id, newId);
      }
      if (updatedEdge.targetHandle && updatedEdge.targetHandle.includes(id)) {
        updatedEdge.targetHandle = updatedEdge.targetHandle.replace(id, newId);
      }

      updatedEdge.id = `${updatedEdge.source}-${updatedEdge.sourceHandle}-${updatedEdge.target}-${updatedEdge.targetHandle}`;

      return updatedEdge;
    });

    const clonedNode = cloneDeep(originalNode);

    const newNode = {
      ...clonedNode,
      id: newId,
      position: {
        x: clonedNode.position.x,
        y: clonedNode.position.y,
      },
      positionAbsolute: {
        x: clonedNode.positionAbsolute.x,
        y: clonedNode.positionAbsolute.y,
      },
      data: {
        ...clonedNode.data,
        id: newId,
      },
      selected: false,
    };

    const dataKeys = ['inputParams', 'inputAnchors', 'outputAnchors'];

    for (const key of dataKeys) {
      for (const item of newNode.data[key]) {
        if (item.id) {
          item.id = item.id.replace(id, newId);
        }
      }
    }

    nodes[nodeIndex] = newNode;
    reactFlowInstance.setNodes(nodes);
    reactFlowInstance.setEdges(newEdges);

    debouncedSaveData();
  };

  return (
    <flowContext.Provider
      value={{
        reactFlowInstance,
        setReactFlowInstance,
        deleteNode,
        deleteEdge,
        duplicateNode,
        debouncedSaveData,
        history,
        setHistory,
        redoArray,
        setRedoArray,
        currentVal,
        setCurrentVal,
        debugData,
        setDebugData,
        checkNode,
        faultyId,
        debugErrorMsg,
        otherId,
        loading,
        setLoading,
        messages,
        setMessages,
        sendMessage,
        changeId,
        showInputBox,
        setShowInputBox,
        setConversationId,
        currentNodeIds,
      }}
    >
      {children}
    </flowContext.Provider>
  );
};

ReactFlowContext.propTypes = {
  children: PropTypes.any,
};
